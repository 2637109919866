import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Navigate,
} from 'react-router-dom';
import { Login, Register } from '../pages';
import { ActionEnum } from '../enums/enums';

const Routes = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route
            path='/'
            element={
              <Navigate to={`/register?action=${ActionEnum.SEND_OTP}`} />
            }
          />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
