import { CSSProperties } from 'react';
import "./Wave.scss";

// interface SpriteProps {
//   width: number;
//   height: number;
//   id: string;
//   style?: CSSProperties;
//   className?: string;
// }

const Wave = () => {
  return (
    <div className="wave">
      <svg viewBox="0 0 1920 174" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.3" d="M1752 80.1169C1828.7 72.6365 1900 126.826 1921 156.389V175H-1V53.014C41.386 54.5724 84.2765 127.299 165.012 128.078C245.747 128.857 265.416 70.5624 315.5 67.1423C372.5 63.2499 406.712 162.623 564.65 156.389C722.588 150.156 775.779 18.0983 855 18.0983C917.5 18.0983 1042.9 90.1006 1112.5 119.819C1183 149.92 1261.89 171.198 1383.5 171.977C1505.11 172.756 1656.13 89.4675 1752 80.1169Z" fill="url(#paint0_linear_62_213)" stroke="white"/>
        <path opacity="0.3" d="M145 33.0922C107.539 34.8155 20 126.964 -1 156.443V175H1921V53.3655C1878.61 54.9194 1835.72 127.436 1754.99 128.213C1674.25 128.99 1654.58 70.8634 1604.5 67.4532C1547.5 63.572 1457.5 142.908 1375 142.908C1216.94 142.908 1131.72 1 1052.5 1C990 1 877.104 90.3453 807.5 119.978C737 149.993 658.107 171.209 536.5 171.986C414.893 172.763 199.5 30.585 145 33.0922Z" fill="url(#paint1_linear_62_213)" stroke="#F27949"/>
        <defs>
        <linearGradient id="paint0_linear_62_213" x1="960" y1="175" x2="960" y2="18.0983" gradientUnits="userSpaceOnUse">
        <stop stopColor="#472886" stopOpacity="0"/>
        <stop offset="1" stopColor="#401D85"/>
        </linearGradient>
        <linearGradient id="paint1_linear_62_213" x1="960" y1="175" x2="960" y2="18.5505" gradientUnits="userSpaceOnUse">
        <stop stopColor="#502E94" stopOpacity="0"/>
        <stop offset="1" stopColor="#401D85"/>
        </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Wave;
