import { useDispatch, useSelector } from 'react-redux';
import { RegisterLoginWrapper } from '../../../../components';
import {
  login,
  loginSuccess,
  signUp,
  signUpSuccess,
  updatePin,
  updatePinSuccess,
} from '../../../../redux/auth/authActions';
import { alert, jsonApiHeader } from '../../../../utils/Utility';
import { useNavigate } from 'react-router-dom';
import { ActionEnum } from '../../../../enums/enums';
import { useEffect } from 'react';

const VerifyPin = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    userData,
    loadingSignUp,
    signUpData,
    loadingLogin,
    loginData,
    loadingUpdatePin,
    updatePinData,
  } = useSelector((state: any) => state.authReducer);

  const processVerifyPin = (data: any) => {
    if (userData?.phoneNumber && userData?.pin) {
      if (userData?.pin === data?.pin) {
        const header = jsonApiHeader(userData?.sessionId);
        if (props.flow) {
          dispatch(
            updatePin(header, {
              number: `${userData?.phoneNumber}`,
              countryCode: '+91',
              password: data?.pin,
              clientId: 'Zebb',
            })
          );
        } else {
          dispatch(
            signUp(header, {
              number: `${userData?.phoneNumber}`,
              countryCode: '+91',
              password: data?.pin,
              clientId: 'Zebb',
              realm: 'ZebbUser',
            })
          );
        }
      } else {
        alert('Incorrect Pin!');
        navigate(-1);
      }
    } else {
      window.location.href = `/`;
    }
  };

  useEffect(() => {
    dispatch(signUpSuccess(null));
    dispatch(loginSuccess(null));
    dispatch(updatePinSuccess(null));
  }, []);

  useEffect(() => {
    if (
      signUpData?.session_state ||
      loginData?.verified ||
      updatePinData?.session_state
    ) {
      localStorage.setItem(
        'accessData',
        signUpData || loginData || updatePinData
      );
      alert(`${signUpData ? 'Sign-Up' : 'Login'} Successful!`, 'success');
      navigate(`/dashboard`);
    }
  }, [signUpData, loginData, updatePinData]);

  return (
    <>
      <RegisterLoginWrapper
        ColOne={{ icon: 'lock', align: 'center' }}
        ColTwo={{
          successMessage: `You have successfully verified your ${
            props.flow ? 'mobile' : 'email'
          }`,
          title: 'Confirm your 4 digit PIN',
          subtitle:
            'Please confirm your 4 digit pin and do not share it with anyone',
          inputType: 'pin',
          button: 'Continue',
          buttonLoader: loadingSignUp || loadingLogin || loadingUpdatePin,
          onButtonClick: (val) => processVerifyPin(val),
          infoLabel: (
            <span style={{ color: 'var(--grey-one)' }}>
              <a
                href={`/${props.flow ? 'login' : 'register'}?action=${
                  ActionEnum.SEND_OTP
                }`}
                style={{ color: 'var(--white)', textDecoration: 'underline' }}
              >
                Back To Home
              </a>
            </span>
          ),
        }}
      />
    </>
  );
};

export default VerifyPin;
