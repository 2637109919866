import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RegisterLoginWrapper } from '../../../../components';
import { ActionEnum } from '../../../../enums/enums';
import {
  sendOTPLogin,
  sendOTPLoginSuccess,
  storeUserData,
} from '../../../../redux/auth/authActions';
import { jsonApiHeader } from '../../../../utils/Utility';

const ForgotPinView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userData, loadingSendOTPLogin, sendOTPLoginData } = useSelector(
    (state: any) => state.authReducer
  );

  const processSendOTP = () => {
    const header = jsonApiHeader();
    dispatch(
      sendOTPLogin(header, {
        number: userData?.phoneNumber,
        countryCode: '+91',
        journey: 'FORGOT_PIN',
      })
    );
  };

  useEffect(() => {
    dispatch(sendOTPLoginSuccess(null));
  }, []);

  useEffect(() => {
    if (sendOTPLoginData) {
      dispatch(
        storeUserData({
          ...userData,
          sessionId: sendOTPLoginData.sessionId,
        })
      );
      navigate(`/login?action=${ActionEnum.VERIFY_OTP}&flow=forgot_pin`);
    }
  }, [sendOTPLoginData]);

  return (
    <>
      <RegisterLoginWrapper
        ColOne={{ icon: 'lock' }}
        ColTwo={{
          title: 'Forgot PIN?',
          subtitle: 'We will send OTP to your registered mobile number',
          forgotPin: true,
          button: 'Create a new pin',
          onButtonClick: () => processSendOTP(),
          buttonLoader: loadingSendOTPLogin,
          infoLabel: (
            <a
              href={`/login?action=${ActionEnum.SEND_OTP}`}
              style={{
                color: 'var(--white)',
                textDecoration: 'underline',
                display: 'block',
              }}
            >
              Back to home
            </a>
          ),
        }}
      />
    </>
  );
};

export default ForgotPinView;
