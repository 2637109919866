import './Header.scss';
import { Sprite } from "../../components";
import { useState } from 'react';

const Header = () => {
  
  const [isOpen, setIsOpen] = useState(false);
  function toggleMenu(){
    setIsOpen(!isOpen)
  }
  return (
    <div className={`header`}>
      <div className="zw-container header-container">

        <a className="header-logo">
          <Sprite id={'zebb-logo'} width={70} height={45} />
        </a>
        <a className={`hamburger-icon ${isOpen?'is-opened':''}`} onClick={toggleMenu}>
          <svg viewBox="5 5 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="stats">
            <path id="top-line" d="M6 7H15" stroke="currentColor" stroke-linecap="round"/>
            <path id="center-line" d="M6 12H18" stroke="currentColor" stroke-linecap="round"/>
            <path id="bottom-line" d="M6 17H12" stroke="currentColor" stroke-linecap="round"/>
            </g>  
          </svg>

        </a>
        <nav className={`links ${isOpen?'is-opened':''}`}>
          <ul>
            <li>
              <a href='https://zebpay.com/about-us/' target='_blank'>
                About ZEBB
              </a>
            </li>
            <li>
              <a href='https://help.zebpay.com/support/home' target='_blank'>
                Support
              </a>
            </li>
            <li>
              <a href={`/register`} target='_blank' rel="noreferrer">
                Sign Up
              </a>
            </li>
            <li>
              <a href={`/login`} target='_blank' rel="noreferrer">
                Login
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
