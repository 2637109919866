import { Typography } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RegisterLoginWrapper } from '../../../../components';
import { ActionEnum } from '../../../../enums/enums';
import {
  sendOTPLogin,
  sendOTPLoginSuccess,
  storeUserData,
} from '../../../../redux/auth/authActions';
import { jsonApiHeader } from '../../../../utils/Utility';

const Login = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData, loadingSendOTPLogin, sendOTPLoginData } = useSelector(
    (state: any) => state.authReducer
  );

  const processLogin = (data: any) => {
    const header = jsonApiHeader(undefined);
    dispatch(
      sendOTPLogin(header, {
        number: data?.phoneNumber,
        countryCode: '+91',
        realm: 'ZebbUser',
        journey: 'LOGIN',
        tncAccepted: true,
      })
    );
    dispatch(
      storeUserData({
        ...userData,
        phoneNumber: data?.phoneNumber,
        countryCode: "+91",
      })
    );
  };
  
  useEffect(() => {
    dispatch(sendOTPLoginSuccess(null));
  }, []);

  useEffect(() => {
    if (sendOTPLoginData) {
      dispatch(
        storeUserData({
          ...userData,
          sessionId: sendOTPLoginData?.sessionId,
        })
      );
      navigate(`/login?action=${sendOTPLoginData.next_action?.toLowerCase()}`);
    }
  }, [sendOTPLoginData]);

  return (
    <>
      <RegisterLoginWrapper
        ColOne={{
          icon: 'money-plant',
          subtitleOne: (
            <Title level={1} style={{ color: 'var(--white)', margin: 0 }}>
              World's <span style={{ color: 'var(--orange)' }}>Simplest</span>{' '}
              Bitcoin SIP
            </Title>
          ),
          subtitleTwo: (
            <Text style={{ color: 'var(--white)' }}>
              Bitcoin made simple and easy
            </Text>
          ),
        }}
        ColTwo={{
          title: 'Login to ZEBB',
          subtitle:
            'Please enter your registered mobile number, we will send OTP to verify your details.',
          inputType: 'phoneNumber',
          button: 'Continue',
          onButtonClick: (val) => {
            processLogin(val);
          },
          buttonLoader: loadingSendOTPLogin,
          infoLabel: (
            <span style={{ color: 'var(--grey-one)' }}>
              Don't have an account?{' '}
              <a
                href='javascript:void(0)'
                style={{ color: 'var(--white)', textDecoration: 'underline' }}
                onClick={() =>
                  navigate(`/register?action=${ActionEnum.SEND_OTP}`)
                }
              >
                Create an account
              </a>
            </span>
          ),
        }}
      />
    </>
  );
};

export default Login;
