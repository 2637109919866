export const SEND_OTP_SIGN_UP_REQUEST = 'SEND_OTP_SIGN_UP_REQUEST';
export const SEND_OTP_SIGN_UP_SUCCESS = 'SEND_OTP_SIGN_UP_SUCCESS';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const STORE_USER_DATA = 'STORE_USER_DATA';
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SEND_OTP_LOGIN_REQUEST = 'SEND_OTP_LOGIN_REQUEST';
export const SEND_OTP_LOGIN_SUCCESS = 'SEND_OTP_LOGIN_SUCCESS';
export const UPDATE_PIN_REQUEST = 'UPDATE_PIN_REQUEST';
export const UPDATE_PIN_SUCCESS = 'UPDATE_PIN_SUCCESS';
