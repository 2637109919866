import { memo } from 'react';
import Header from './Header/Header';
import Content from "./Content/Content";

const Wrapper = () => {
  return <>
    <Header />
    <div className={`fake-div`} />
    <Content />
  </>;
};

export default memo(Wrapper);
