import React from "react";
import './Content.scss';
import Routes from '../../routes/Routes';
import Wave from "../../components/Wave/Wave";
// import Wave from "@assets./"

const Content = () => {
  return (
    <main className={`main centered`}>
      <Wave/>
      <Routes />
    </main>
  );
};

export default Content;
