import axios from 'axios';
import { alert } from '../../utils/Utility';
import { ENV_CONFIG } from '../../config';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  SEND_OTP_SIGN_UP_REQUEST,
  SEND_OTP_SIGN_UP_SUCCESS,
  STORE_USER_DATA,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SEND_OTP_LOGIN_SUCCESS,
  SEND_OTP_LOGIN_REQUEST,
  UPDATE_PIN_REQUEST,
  UPDATE_PIN_SUCCESS,
} from './authActionTypes';
import { APIEndpoints } from '../../API/Endpoint';

const BASE_URL = ENV_CONFIG.BASE_URL;

export const storeUserData = (data: any) => {
  return {
    type: STORE_USER_DATA,
    payload: data,
  };
};

export const sendOTPSignUpRequest = () => {
  return { type: SEND_OTP_SIGN_UP_REQUEST };
};

export const sendOTPSignUpSuccess = (data: any) => {
  return { type: SEND_OTP_SIGN_UP_SUCCESS, payload: data };
};

export const loginRequest = () => {
  return { type: LOGIN_REQUEST };
};

export const loginSuccess = (data: any) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
};

export const verifyOTPRequest = () => {
  return { type: VERIFY_OTP_REQUEST };
};

export const verifyOTPSuccess = (data: any) => {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload: data,
  };
};

export const verifyEmailRequest = () => {
  return {
    type: VERIFY_EMAIL_REQUEST,
  };
};

export const verifyEmailSuccess = (data: any) => {
  return {
    type: VERIFY_EMAIL_SUCCESS,
    payload: data,
  };
};

export const sendEmailRequest = () => {
  return {
    type: SEND_EMAIL_REQUEST,
  };
};

export const sendEmailSuccess = (data: any) => {
  return {
    type: SEND_EMAIL_SUCCESS,
    payload: data,
  };
};

export const signUpRequest = () => {
  return {
    type: SIGN_UP_REQUEST,
  };
};

export const signUpSuccess = (data: any) => {
  return {
    type: SIGN_UP_SUCCESS,
    payload: data,
  };
};

export const sendOTPLoginRequest = () => {
  return {
    type: SEND_OTP_LOGIN_REQUEST,
  };
};

export const sendOTPLoginSuccess = (data: any) => {
  return {
    type: SEND_OTP_LOGIN_SUCCESS,
    payload: data,
  };
};

export const updatePinRequest = () => {
  return {
    type: UPDATE_PIN_REQUEST,
  };
};

export const updatePinSuccess = (data: any) => {
  return {
    type: UPDATE_PIN_SUCCESS,
    payload: data,
  };
};

export const sendOTPSignUp =
  (headers: any, body: any) => (dispatch: any, getState: any) => {
    dispatch(sendOTPSignUpRequest());
    axios
      .post(`${BASE_URL}${APIEndpoints.OTP_SEND}`, body, { headers })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          alert(data.message, 'error');
          dispatch(sendOTPSignUpSuccess(null));
          return;
        }
        if (
          data &&
          data.error === '0' &&
          data.data &&
          (data.data.length || Object.keys(data.data).length)
        )
          dispatch(sendOTPSignUpSuccess(data.data));
        else {
          alert('Unable to signup!', 'error');
          dispatch(sendOTPSignUpSuccess(null));
        }
      })
      .catch((e) => {
        alert(e.message, 'error');
        dispatch(sendOTPSignUpSuccess(null));
      });
  };

export const login =
  (headers: any, body: any) => (dispatch: any, getState: any) => {
    dispatch(loginRequest());
    axios
      .post(`${BASE_URL}${APIEndpoints.LOGIN}`, body, {
        headers,
      })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          alert(data.message, 'error');
          dispatch(loginSuccess(null));
          return;
        }
        if (
          data &&
          data.error === '0' &&
          data.data &&
          (data.data.length || Object.keys(data.data).length)
        )
          dispatch(loginSuccess(data.data));
        else {
          alert('Unable to login!', 'error');
          dispatch(loginSuccess(null));
        }
      })
      .catch((e) => {
        alert(e.message, 'error');
        dispatch(loginSuccess(null));
      });
  };

export const verifyOTP =
  (headers: any, body: any) => (dispatch: any, getState: any) => {
    dispatch(verifyOTPRequest());
    axios
      .post(`${BASE_URL}${APIEndpoints.OTP_VERIFY}`, body, { headers })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          alert(data.message, 'error');
          dispatch(verifyOTPSuccess(null));
          return;
        }
        if (
          data &&
          data.error === '0' &&
          data.data &&
          (data.data.length || Object.keys(data.data).length)
        ) {
          dispatch(verifyOTPSuccess(data.data));
        } else {
          alert('OTP Verification failed', 'error');
          dispatch(verifyOTPSuccess(null));
        }
      })
      .catch((e) => {
        alert(e.message, 'error');
        dispatch(verifyOTPSuccess(null));
      });
  };

export const sendOTPLogin =
  (headers: any, body: any) => (dispatch: any, getState: any) => {
    dispatch(sendOTPLoginRequest());
    axios
      .post(`${BASE_URL}${APIEndpoints.OTP_SEND}`, body, { headers })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          alert(data.message, 'error');
          dispatch(sendOTPLoginSuccess(null));
          return;
        }
        if (
          data &&
          data.error === '0' &&
          data.data &&
          (data.data.length || Object.keys(data.data).length)
        ) {
          dispatch(sendOTPLoginSuccess(data.data));
        } else {
          alert('Failure Sending OTP', 'error');
          dispatch(sendOTPLoginSuccess(null));
        }
      })
      .catch((e) => {
        alert(e.message, 'error');
        dispatch(sendOTPLoginSuccess(null));
      });
  };

export const verifyEmail =
  (headers: any, body: any) => (dispatch: any, getState: any) => {
    dispatch(verifyEmailRequest());
    axios
      .post(`${BASE_URL}${APIEndpoints.EMAIL_VERIFY}`, body, { headers })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          alert(data.message, 'error');
          dispatch(verifyEmailSuccess(null));
          return;
        }
        if (
          data &&
          data.error === '0' &&
          data.data &&
          (data.data.length || Object.keys(data.data).length)
        ) {
          dispatch(verifyEmailSuccess(data.data));
        } else {
          alert('Email Verification failed', 'error');
          dispatch(verifyEmailSuccess(null));
        }
      })
      .catch((e) => {
        alert(e.message, 'error');
        dispatch(verifyEmailSuccess(null));
      });
  };

export const sendEmail =
  (headers: any, body: any) => (dispatch: any, getState: any) => {
    dispatch(sendEmailRequest());
    axios
      .post(`${BASE_URL}${APIEndpoints.EMAIL_SEND}`, body, { headers })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          alert(data.message, 'error');
          dispatch(sendEmailSuccess(null));
          return;
        }
        if (
          data &&
          data.error === '0' &&
          data.data &&
          (data.data.length || Object.keys(data.data).length)
        ) {
          dispatch(sendEmailSuccess(data.data));
        } else {
          alert("Couldn't send email", 'error');
          dispatch(sendEmailSuccess(null));
        }
      })
      .catch((e) => {
        alert(e.message, 'error');
        dispatch(sendEmailSuccess(null));
      });
  };

export const signUp =
  (headers: any, body: any) => (dispatch: any, getState: any) => {
    dispatch(signUpRequest());
    axios
      .post(`${BASE_URL}${APIEndpoints.SIGNUP}`, body, { headers })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          alert(data.message, 'error');
          dispatch(signUpSuccess(null));
          return;
        }
        if (
          data &&
          data.error === '0' &&
          data.data &&
          (data.data.length || Object.keys(data.data).length)
        ) {
          dispatch(signUpSuccess(data.data));
        } else {
          alert('Email Verification failed', 'error');
          dispatch(signUpSuccess(null));
        }
      })
      .catch((e) => {
        alert(e.message, 'error');
        dispatch(signUpSuccess(null));
      });
  }

export const updatePin = (headers: any, body: any) => (dispatch: any, getState: any) => {
  dispatch(updatePinRequest());
  axios.post(`${BASE_URL}${APIEndpoints.UPDATE_PIN}`, body, { headers })
  .then(({ data }) => {
    if (data && data.error !== '0' && data.message) {
      alert(data.message, 'error');
      dispatch(updatePinSuccess(null));
      return;
    }
    if (
      data &&
      data.error === '0' &&
      data.data &&
      (data.data.length || Object.keys(data.data).length)
    ) {
      dispatch(updatePinSuccess(data.data));
    } else {
      alert('Update pin failed', 'error');
      dispatch(updatePinSuccess(null));
    }
  })
  .catch((e) => {
    alert(e.message, 'error');
    dispatch(updatePinSuccess(null));
  });
}
