import { CSSProperties } from 'react';
import sprite from './icons/icons.svg';

interface SpriteProps {
  width: number;
  height: number;
  id: string;
  style?: CSSProperties;
  className?: string;
}

const Sprite = (props: SpriteProps) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      style={{ ...props.style }}
      className={`${props.className || ''}`}
    >
      <use href={`${sprite}#${props.id}`} />
    </svg>
  );
};

export default Sprite;
