export enum ActionEnum {
  SEND_OTP = 'send_otp',
  VERIFY_OTP = 'verify_otp',
  SEND_EMAIL = 'send_email',
  VERIFY_EMAIL = 'verify_email',
  SIGNUP = 'signup',
  LOGIN = 'login',
  VERIFY_PIN = 'verify_pin',
  SEND_OTP_FOR_LOGGED_IN_USER = 'send_otp_for_logged_in_user',
  VERIFY_OTP_FOR_LOGGED_IN_USER = 'verify_otp_for_logged_in_user',
  UPDATE_PIN = 'update_pin'
}
