import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_REQUEST,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  SEND_OTP_SIGN_UP_REQUEST,
  SEND_OTP_SIGN_UP_SUCCESS,
  STORE_USER_DATA,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SEND_OTP_LOGIN_REQUEST,
  SEND_OTP_LOGIN_SUCCESS,
  UPDATE_PIN_REQUEST,
  UPDATE_PIN_SUCCESS,
} from './authActionTypes';

const initialState = {
  userData: null,
  loadingSendOTPSignUp: false,
  sendOTPSignUpData: null,
  loadingLogin: false,
  loginData: null,
  loadingSendOTPLogin: false,
  sendOTPLoginData: null,
  loadingVerifyOTP: false,
  verifyOTPData: null,
  loadingVerifyEmail: false,
  verifyEmailData: null,
  loadingSendEmail: false,
  sendEmailData: null,
  loadingSignUp: false,
  signUpData: null,
  loadingUpdatePin: false,
  updatePinData: null,
};
const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STORE_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case SEND_OTP_SIGN_UP_REQUEST:
      return {
        ...state,
        loadingSendOTPSignUp: true,
        sendOTPSignUpData: null,
      };
    case SEND_OTP_SIGN_UP_SUCCESS:
      return {
        ...state,
        loadingSendOTPSignUp: false,
        sendOTPSignUpData: action.payload,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loadingLogin: true,
        loginData: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loadingLogin: false,
        loginData: action.payload,
      };
    case SEND_OTP_LOGIN_REQUEST:
      return {
        ...state,
        loadingSendOTPLogin: true,
        sendOTPLoginData: null,
      };
    case SEND_OTP_LOGIN_SUCCESS:
      return {
        ...state,
        loadingSendOTPLogin: false,
        sendOTPLoginData: action.payload,
      };
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        loadingVerifyOTP: true,
        verifyOTPData: null,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loadingVerifyOTP: false,
        verifyOTPData: action.payload,
      };
    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        loadingVerifyEmail: true,
        verifyEmailData: null,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loadingVerifyEmail: false,
        verifyEmailData: action.payload,
      };
    case SEND_EMAIL_REQUEST:
      return {
        ...state,
        loadingSendEmail: true,
        sendEmailData: null,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loadingSendEmail: false,
        sendEmailData: action.payload,
      };
    case SIGN_UP_REQUEST:
      return {
        ...state,
        loadingSignUp: true,
        signUpData: null,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        loadingSignUp: false,
        signUpData: action.payload,
      }
    case UPDATE_PIN_REQUEST:
      return {
        ...state,
        loadingUpdatePin: true,
        updatePinData: null,
      };
    case UPDATE_PIN_SUCCESS:
      return {
        ...state,
        loadingUpdatePin: false,
        updatePinData: action.payload,
      }
    default:
      return state;
  }
};

export default authReducer;
