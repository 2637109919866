import { Col, Row } from 'antd';
import { Col1, Col2 } from './Components';
import { RegisterLoginWrapperInterface } from './RegisterLoginWrapperInterface';

const RegisterLoginWrapper = ({
  ColOne,
  ColTwo,
}: RegisterLoginWrapperInterface) => {
  return (
    <>
    <Row>
      <Col 
        xs={{span:24}}  
        sm={{span:18,offset:3}} 
        md={{span:7,offset:1}} 
        lg={{span:6,offset:2}}
        xl={{span:6,offset:3}}
        >
        <Col1
          icon={ColOne.icon}
          subtitleOne={ColOne.subtitleOne}
          subtitleTwo={ColOne.subtitleTwo}
          align={ColOne.align}
        />
      </Col>
      <Col 
        xs={{span:24}}  
        sm={{span:18,offset:3}} 
        md={{span:14,offset:2}}
        lg={{span:12,offset:2}}
        xl={{span:9,offset:3}}
        >
        <Col2
          button={ColTwo.button}
          checkBoxLabel={ColTwo.checkBoxLabel}
          infoLabel={ColTwo.infoLabel}
          inputType={ColTwo.inputType}
          onButtonClick={ColTwo.onButtonClick}
          onCheck={ColTwo.onCheck}
          onInputChange={ColTwo.onInputChange}
          subtitle={ColTwo.subtitle}
          successMessage={ColTwo.successMessage}
          title={ColTwo.title}
          buttonLoader={ColTwo.buttonLoader}
          forgotPin={ColTwo.forgotPin}
        />
      </Col>
    </Row>
    </>
  );
};

export default RegisterLoginWrapper;
