import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RegisterLoginWrapper } from '../../../../components';
import { sendEmail, sendEmailSuccess, storeUserData } from '../../../../redux/auth/authActions';
import { jsonApiHeader } from '../../../../utils/Utility';

const SendEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData, loadingSendEmail, sendEmailData } =
    useSelector((state: any) => state.authReducer);

  useEffect(() => {
    dispatch(sendEmailSuccess(null));
  }, []);

  useEffect(() => {
    if (sendEmailData) {
      navigate(`/register?action=${sendEmailData.next_action?.toLowerCase()}`); // next step as per response
    }
  }, [sendEmailData]);

  const processSendEmail = (data: any) => {
    const header = jsonApiHeader(userData?.sessionId);
    dispatch(
      storeUserData({
        ...userData,
        email: data?.email,
      })
    );
    dispatch(
      sendEmail(header, {
        number: userData?.phoneNumber,
        countryCode: '+91',
        email: data?.email,
        realm: 'ZebbUser',
        journey: 'SIGNUP',
      })
    );
  };

  return (
    <>
      <RegisterLoginWrapper
        ColOne={{
          icon: 'send-email',
          align:'center'
        }}
        ColTwo={{
          title: 'Enter your email',
          subtitle:
            'Enter the email address we will send verification link on your email',
          inputType: 'email',
          button: 'Continue',
          buttonLoader: loadingSendEmail,
          onButtonClick: (val) => {
            processSendEmail(val);
          },

          infoLabel: (
            <span style={{ color: 'var(--grey-one)' }}>
              <a
                href='/'
                style={{ color: 'var(--white)', textDecoration: 'underline' }}
              >
                Back To Home
              </a>
            </span>
          ),
        }}
      />
    </>
  );
};

export default SendEmail;
