import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { Form, Button, Checkbox, Input } from 'antd';
import { ColTwo, FormValues } from '../../RegisterLoginWrapperInterface';

interface FormInterface extends ColTwo {
  formValues: FormValues;
  form: any;
  onFormFinish: (val: FormValues) => void;
}

const Col2Form = ({
  inputType,
  formValues,
  form,
  onFormFinish,
  checkBoxLabel,
  onCheck,
  button,
  buttonLoader,
}: FormInterface) => {
  const [checked, setChecked] = useState(false);
  const [pin, setPin] = useState('');
  return (
    <Form
      initialValues={formValues}
      form={form}
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 24 }}
      style={{ width: '100%' }}
      onFinish={onFormFinish}
    >
      {inputType === 'phone_email' ? (
        <Form.Item
          name={inputType}
          style={{ width: '100%' }}
          rules={[
            {
              required: true,
              message: 'Required field.',
            },
            {
              pattern: new RegExp(/^(?:\d{10}|\w.+@\w+\.\w{2,3})$/),
              message: 'Please enter a valid value',
            }
          ]}
        >
          <Input
            placeholder={'Enter your mobile number or email'}
            className={`input`}
            style={{
              borderRadius: '25px',
              width: '100%',
            }}
          />
        </Form.Item>
      ) : null}
      {inputType === 'phoneNumber' ||
      inputType === 'verifyCode' ||
      inputType === 'email' ? (
        <Form.Item
          name={inputType}
          className='custom-select'
          style={{ width: '100%' }}
          rules={[
            {
              required: true,
              message: 'Required field.',
            },
            {
              ...(inputType === 'phoneNumber' || inputType === 'verifyCode'
                ? {
                    pattern: new RegExp(/^[0-9\b]+$/),
                    message: 'Please enter only numeric characters',
                  }
                : undefined),
            },
            {
              ...(inputType === 'email'
                ? {
                    type: 'email',
                    message: 'Please enter a valid email',
                  }
                : undefined),
            },
            {
              ...(inputType === 'phoneNumber' || inputType === 'verifyCode'
                ? {
                    len: inputType === 'phoneNumber' ? 10 : 6,
                    message:
                      inputType === 'phoneNumber'
                        ? 'Please enter a valid number'
                        : 'Please enter 6 digit code',
                  }
                : undefined),
            },
          ]}
        >
          <span className={`input-area`}>
            {inputType === 'phoneNumber' ? (
              <>
                <div className={`number`}>+91</div>
                <Input placeholder='Enter Mobile Number' className={`input`} />
              </>
            ) : inputType === 'verifyCode' ? (
              <Input
                placeholder='Enter Verification Code'
                className={`input`}
                style={{
                  borderRadius: '25px',
                  width: '100%',
                  textAlign: 'center',
                }}
              />
            ) : inputType === 'email' ? (
              <Input
                placeholder='Enter Email'
                className={`input`}
                style={{
                  borderRadius: '25px',
                  width: '100%',
                  textAlign: 'center',
                }}
              />
            ) : null}
          </span>
        </Form.Item>
      ) : null}
      {inputType === 'pin' ? (
        <Form.Item
          name={inputType}
          label={'4 Digit PIN'}
          style={{ width: '100%' }}
          className={`form-label`}
        >
          <OtpInput
            value={pin}
            onChange={(val: string) => setPin(val)}
            numInputs={4}
            inputStyle={{
              width: 50,
              margin: '0 10px 0 10px',
              padding: '10px',
              border: '1px solid var(--grey-one)',
              borderRadius: '10px',
            }}
            containerStyle={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            isInputSecure
          />
        </Form.Item>
      ) : null}
      {checkBoxLabel ? (
        <Form.Item
          name='checked'
          valuePropName='checked'
          rules={[
            {
              required: true,
              message: 'Required field.',
            },
          ]}
        >
          <Checkbox
            className={`checkbox ${checked ? 'checked' : ''}`}
            onChange={() => {
              if (onCheck) onCheck();
              setChecked(!checked);
            }}
          >
            {checkBoxLabel}
          </Checkbox>
        </Form.Item>
      ) : null}
      {button ? (
        <Form.Item style={{ margin: '0' }}>
          <Button
            htmlType='submit'
            type='primary'
            className={`button ${
              Boolean(
                (checkBoxLabel && !checked) ||
                  (inputType === 'pin' && pin.length !== 4)
              )
                ? 'disabled'
                : ''
            }`}
            loading={buttonLoader}
            disabled={Boolean(
              (checkBoxLabel && !checked) ||
                (inputType === 'pin' && pin.length !== 4)
            )}
          >
            {button}
          </Button>
        </Form.Item>
      ) : null}
    </Form>
  );
};

export default Col2Form;
