export enum APIEndpoints {
  LOGIN = "/auth-service/auth/login",
  SIGNUP = "/auth-service/auth/signup",
  OTP_SEND = "/auth-service/otp/send",
  OTP_VERIFY = "/auth-service/otp/verify",
  EMAIL_SEND = "/auth-service/email/send",
  EMAIL_VERIFY = "/auth-service/email/verify",
  LOGIN_REFRESH = "/auth/login/refresh",
  PIN_VERIFY = "/auth/pin/verify",
  OTP_SEND_LOGGED = "/otp/send/logged",
  OTP_VERIFY_LOGGED = "/otp/verify/logged",
  EMAIL_SEND_LOGGED_USER = "/email/send",
  EMAIL_VERIFY_LOGGED_USER = "/email/verify",
  UPDATE_PIN = "/auth-service/auth/pin/update",
}
