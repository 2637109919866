import './styles/app.scss';
import Wrapper from './Wrapper/Wrapper';

const App = () => {
  return (
    <div className={`app`}>
      <Wrapper />
    </div>
  );
};

export default App;
