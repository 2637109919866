import { Typography, Form } from 'antd';
import { ColTwo, FormValues } from '../../RegisterLoginWrapperInterface';
import { Sprite } from '../../..';
import Col2Form from './Col2Form';

const Col2 = ({
  button,
  checkBoxLabel,
  onButtonClick,
  inputType,
  subtitle,
  title,
  successMessage,
  infoLabel,
  onCheck,
  buttonLoader,
  forgotPin,
}: ColTwo) => {
  const { Title, Text } = Typography;

  const onFormFinish = (val: FormValues) => {
    if (onButtonClick) onButtonClick(val);
    form.resetFields();
  };
  const [form] = Form.useForm();
  const formValues: FormValues = {
    phoneNumber: '',
    checked: false,
    pin: '',
    verifyCode: '',
    phone_email: '',
    remember: true,
  };

  return (
    <div className={`col-2`}>
      <div className={`content`}>
        {successMessage ? (
          <Text className={`success-message`}>
            <Sprite
              id='success-tick'
              width={14}
              height={14}
              style={{ position: 'relative', top: '2px' }}
            />
            &nbsp;{successMessage}
          </Text>
        ) : null}
        {title ? (
          <Title level={1} style={{ color: 'var(--grey)', margin: 0 }}>
            {title}
          </Title>
        ) : null}
        {subtitle ? (
          <Text style={{ color: 'var(--grey-one)' }}>{subtitle}</Text>
        ) : null}
        {forgotPin ? (
          <>
            <div className={`forgot-pin`}>
              <div className={`till-24-hours`}>
                <span>
                  <Title level={5} style={{ margin: '0' }}>
                    Till 24 hours
                  </Title>
                  <Text style={{ color: 'var(--grey-one)' }}>
                    of PIN Set-Up
                  </Text>
                </span>
                <span className={`span`}>
                  <Sprite id='success-tick-orange' width={18} height={18} />
                  <Text
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      width: '70px',
                    }}
                  >
                    Deposit
                  </Text>
                </span>
                <span className={`span`}>
                  <Sprite id='success-tick-orange' width={18} height={18} />
                  <Text
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      width: '70px',
                    }}
                  >
                    Buy / Sell
                  </Text>
                </span>
                <span className={`span`}>
                  <Sprite id='success-tick-orange' width={18} height={18} />
                  <Text
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      width: '70px',
                    }}
                  >
                    SIP
                  </Text>
                </span>
                <span className={`span`}>
                  <Sprite id='cross-gray' width={18} height={18} />
                  <Text
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      width: '70px',
                    }}
                  >
                    Withdraw
                  </Text>
                </span>
              </div>
              <div className={`after-24-hours`}>
                <span>
                  <Title level={5} style={{ margin: '0' }}>
                    After 24 hours
                  </Title>
                  <Text style={{ color: 'var(--grey-one)' }}>
                    of PIN Set-Up
                  </Text>
                </span>
                <span className={`span`}>
                  <Sprite id='success-tick-orange' width={18} height={18} />
                  <Text
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      width: '70px',
                    }}
                  >
                    Deposit
                  </Text>
                </span>
                <span className={`span`}>
                  <Sprite id='success-tick-orange' width={18} height={18} />
                  <Text
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      width: '70px',
                    }}
                  >
                    Buy / Sell
                  </Text>
                </span>
                <span className={`span`}>
                  <Sprite id='success-tick-orange' width={18} height={18} />
                  <Text
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      width: '70px',
                    }}
                  >
                    SIP
                  </Text>
                </span>
                <span className={`span`}>
                  <Sprite id='success-tick-orange' width={18} height={18} />
                  <Text
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      width: '70px',
                    }}
                  >
                    Withdraw
                  </Text>
                </span>
              </div>
            </div>
          </>
        ) : null}
        {button || inputType || checkBoxLabel ? (
          <Col2Form
            button={button}
            checkBoxLabel={checkBoxLabel}
            form={form}
            formValues={formValues}
            inputType={inputType}
            onCheck={onCheck}
            onFormFinish={onFormFinish}
            buttonLoader={buttonLoader}
          />
        ) : null}
      </div>
      {infoLabel ? <div className={`info-label`}>{infoLabel}</div> : null}
    </div>
  );
};

export default Col2;
