import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RegisterLoginWrapper } from '../../../../components';
import {
  sendEmail,
  verifyEmail,
  verifyEmailSuccess,
} from '../../../../redux/auth/authActions';
import { alert, jsonApiHeader } from '../../../../utils/Utility';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { Title } = Typography;
  const dispatch = useDispatch();
  const [resendEmailState, setResendEmailState] = useState(false);
  const [enableManual, setEnableManual] = useState(false);
  const [lastAttempt, setLastAttempt] = useState(false);
  const { userData, verifyEmailData } = useSelector(
    (state: any) => state.authReducer
  );

  setTimeout(() => {
    setEnableManual(true);
  }, 120000);

  const getAnswer = async () => {
    checkEmailIfVerified();
  };

  useEffect(() => {
    dispatch(verifyEmailSuccess(null));
  }, []);

  useEffect(() => {
    if (!enableManual) {
      const timer = setInterval(getAnswer, 2000);
      return () => clearInterval(timer);
    }
  }, [enableManual]);

  useEffect(() => {
    if (verifyEmailData) {
      if (verifyEmailData?.verified) {
        setEnableManual(false);
        alert('Email Verified', 'success');
        navigate(`/login?action=create_pin&flow=forgot_pin`);
      }
      if (lastAttempt) {
        alert(`Attempts exhausted. Please start again!`);
        window.location.href = '/login';
        return;
      }
      if (verifyEmailData?.attempts_left === 0) {
        setLastAttempt(true);
      }
    }
  }, [verifyEmailData]);

  const checkEmailIfVerified = () => {
    const header = jsonApiHeader(userData?.sessionId);
    dispatch(
      verifyEmail(header, {
        email: userData?.email,
      })
    );
  };

  const processResendEmail = () => {
    const header = jsonApiHeader(userData?.sessionId);
    dispatch(
      sendEmail(header, {
        number: userData?.phoneNumber,
        countryCode: '+91',
        email: userData?.email,
        realm: 'ZebbUser',
        journey: 'SIGNUP',
      })
    );
    setResendEmailState(true);
  };

  useEffect(() => {
    if (resendEmailState) {
      alert('Email Sent', 'success');
    }
  }, [resendEmailState]);

  return (
    <>
      <RegisterLoginWrapper
        ColOne={{ icon: 'email-circle' }}
        ColTwo={{
          title: 'Verify your Email',
          subtitle: (
            <>
              We have sent a verification code to{' '}
              <Title level={4} style={{ display: 'inline-block', margin: '0' }}>
                {userData?.email}
              </Title>
              . Click the link inside to get started.
            </>
          ),
          ...(enableManual ? { button: 'Verify Manually' } : null),
          onButtonClick: (val) => {
            checkEmailIfVerified();
            alert(`Please verify the link send to your mail!`, 'open');
          },
          infoLabel: (
            <span style={{ color: 'var(--grey-one)' }}>
              Didn't receive the email?{' '}
              <a
                href='javascript:void(0)'
                style={{ color: 'var(--white)', textDecoration: 'underline' }}
                onClick={() => processResendEmail()}
              >
                Resend Link
              </a>
              <a
                href='/'
                style={{
                  color: 'var(--white)',
                  textDecoration: 'underline',
                  display: 'block',
                  marginTop: '10px',
                }}
              >
                Back To Home
              </a>
            </span>
          ),
        }}
      />
    </>
  );
};

export default VerifyEmail;
