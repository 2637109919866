import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RegisterLoginWrapper } from '../../../../components';
import {
  verifyOTP,
  sendOTPSignUp,
  verifyOTPSuccess,
} from '../../../../redux/auth/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { alert, jsonApiHeader } from '../../../../utils/Utility';
import { useEffect, useState } from 'react';
import { ActionEnum } from '../../../../enums/enums';

const VerifyMobile = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lastAttempt, setLastAttempt] = useState(false);

  const { userData, loadingVerifyOTP, verifyOTPData, loadingSendOTPSignUp } =
    useSelector((state: any) => state.authReducer);

  const processVerifyMobile = async (data: any) => {
    const header = jsonApiHeader(userData?.sessionId);
    dispatch(
      verifyOTP(header, {
        number: userData?.phoneNumber,
        countryCode: '+91',
        otp: data?.verifyCode,
        realm: 'ZebbUser',
      })
    );
  };

  const processResendOTP = () => {
      const header = jsonApiHeader(null);
      dispatch(
        sendOTPSignUp(header, {
          number: userData?.phoneNumber,
          countryCode: '+91',
          realm: 'ZebbUser',
          journey: 'SIGNUP',
          tncAccepted: true,
        })
      );
    };

  useEffect(() => {
    dispatch(verifyOTPSuccess(null));
  }, []);

  useEffect(() => {
    if (verifyOTPData) {
      if (!verifyOTPData?.verified || !verifyOTPData?.next_action) {
        alert('Wrong OTP!');
        return;
      }
      if (lastAttempt) {
        alert(`Attempts exhausted. Please start again!`);
        window.location.href = '/login';
        return;
      }
      if (verifyOTPData?.attempts_left === 0) {
        setLastAttempt(true);
      }
      if (verifyOTPData?.next_action?.toLowerCase() === 'login') {
        navigate(`/login?action=${ActionEnum.VERIFY_PIN}`);
      } else {
        navigate(
          `/register?action=${verifyOTPData.next_action?.toLowerCase()}`
        );
      }
    }
  }, [verifyOTPData]);

  return (
    <>
      <RegisterLoginWrapper
        ColOne={{
          icon: 'phone-otp',
          align: 'center',
        }}
        ColTwo={{
          title: 'Verify your mobile number',
          subtitle: (
            <>
              Please Enter the six-digit code we just sent to your number&nbsp;
              <Title level={4} style={{ color: 'var(--grey)', margin: 0 }}>
                +91 ********{userData?.phoneNumber?.substring(8)}
              </Title>
            </>
          ),
          inputType: 'verifyCode',
          button: 'Verify',
          onButtonClick: (val) => processVerifyMobile(val),
          buttonLoader: loadingVerifyOTP || loadingSendOTPSignUp,
          infoLabel: (
            <span style={{ color: 'var(--grey-one)' }}>
              Didn't receive the code?{' '}
              <a
                href='javascript:void(0)'
                style={{ color: 'var(--white)', textDecoration: 'underline' }}
                onClick={() => processResendOTP()}
              >
                Resend Code
              </a>
              <p>
                {verifyOTPData?.attempts_left
                  ? `Resend Attempts Left - ${verifyOTPData.attempts_left} `
                  : null}
              </p>
              <a
                href='/'
                style={{ color: 'var(--white)', textDecoration: 'underline' }}
              >
                Back To Home
              </a>
            </span>
          ),
        }}
      />
    </>
  );
};

export default VerifyMobile;
