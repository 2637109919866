import { RegisterLoginWrapper } from '../../../../components';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ActionEnum } from '../../../../enums/enums';
import {
  sendOTPSignUp,
  sendOTPSignUpSuccess,
  storeUserData,
} from '../../../../redux/auth/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { jsonApiHeader } from '../../../../utils/Utility';
import { useEffect } from 'react';

const SignUp = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData, loadingSendOTPSignUp, sendOTPSignUpData } = useSelector(
    (state: any) => state.authReducer
  );

  const processSignUp = async (data: any) => {
    if (data?.checked) {
      const header = jsonApiHeader(undefined);
      dispatch(
        storeUserData({
          ...userData,
          phoneNumber: data?.phoneNumber,
          countryCode: '+91',
        })
      );
      dispatch(
        sendOTPSignUp(header, {
          number: data?.phoneNumber,
          countryCode: '+91',
          realm: 'ZebbUser',
          journey: 'SIGNUP',
          tncAccepted: true,
        })
      );
    }
  };
  
  useEffect(() => {
    dispatch(sendOTPSignUpSuccess(null));
  }, []);

  useEffect(() => {
    if (sendOTPSignUpData) {
      dispatch(
        storeUserData({
          ...userData,
          sessionId: sendOTPSignUpData?.sessionId,
        })
      );
      navigate(
        `/register?action=${sendOTPSignUpData.next_action?.toLowerCase()}`
      );
    }
  }, [sendOTPSignUpData]);

  return (
    <>
      <RegisterLoginWrapper
        ColOne={{
          icon: 'money-plant',
          subtitleOne: (
            <Title level={1} style={{ color: 'var(--white)', margin: 0 }}>
              World's <span style={{ color: 'var(--orange)' }}>Simplest</span>{' '}
              Bitcoin SIP
            </Title>
          ),
          subtitleTwo: (
            <Text style={{ color: 'var(--white)' }}>
              Bitcoin made simple and easy
            </Text>
          ),
        }}
        ColTwo={{
          title: 'Create an account',
          subtitle:
            "Let's get you all set up so you can verify your personal account and begin setting up your profile.",
          checkBoxLabel: (
            <>
              I agree to ZEBB's{' '}
              <a
                href='https://zebpay.com/in/terms-of-use/'
                target={'_blank'}
                style={{ color: 'inherit', textDecoration: 'underline' }}
              >
                Terms of Use
              </a>{' '}
              and{' '}
              <a
                href='https://zebpay.com/in/privacy-policy/'
                target={'_blank'}
                style={{ color: 'inherit', textDecoration: 'underline' }}
              >
                Privacy Policy
              </a>
            </>
          ),
          inputType: 'phoneNumber',
          button: 'Continue',
          onButtonClick: (val) => processSignUp(val),
          buttonLoader: loadingSendOTPSignUp,
          infoLabel: (
            <span style={{ color: 'var(--grey-one)' }}>
              Already have an account?{' '}
              <a
                href='javascript:void(0)'
                style={{ color: 'var(--white)', textDecoration: 'underline' }}
                onClick={() => navigate(`/login?action=${ActionEnum.SEND_OTP}`)}
              >
                Login
              </a>
            </span>
          ),
        }}
      />
    </>
  );
};

export default SignUp;
