import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import querystring from 'query-string';
import '../pages.scss';
import { ActionEnum } from '../../enums/enums';
import {
  EnterPin,
  ForgotPin,
  LoginView,
  VerifyEmail,
  VerifyOTP,
} from './Views';
import { CreatePin, VerifyPin } from '../Register/Views';

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [action, setAction] = useState<string | string[] | null>('');
  const [flow, setFlow] = useState<string | string[] | null>('');

  useEffect(() => {
    if (!querystring.parse(location.search)?.action)
      navigate(`/login?action=${ActionEnum.SEND_OTP}`);
    setAction(querystring.parse(location.search)?.action);
    setFlow(querystring.parse(location.search)?.flow || '');
  }, [location]);

  return (
    <div className={`zw-container`}>
      {action === ActionEnum.SEND_OTP ? (
        <LoginView />
      ) : action === ActionEnum.VERIFY_OTP ? (
        <VerifyOTP flow={flow} />
      ) : action === ActionEnum.VERIFY_EMAIL ? (
        <VerifyEmail />
      ) : action === ActionEnum.VERIFY_PIN && !flow ? (
        <EnterPin />
      ) : action === 'forgot_pin' ? (
        <ForgotPin />
      ) : action === 'create_pin' ? (
        <CreatePin flow={flow} />
      ) : action === ActionEnum.VERIFY_PIN && flow ? (
        <VerifyPin flow={flow} />
      ) : null}
    </div>
  );
};

export default Login;
