import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RegisterLoginWrapper } from '../../../../components';
import { ActionEnum } from '../../../../enums/enums';
import {
  login,
  loginSuccess,
  sendOTPLoginSuccess,
  verifyOTPSuccess,
} from '../../../../redux/auth/authActions';
import { alert, jsonApiHeader } from '../../../../utils/Utility';

const EnterPin = () => {
  const dispatch = useDispatch();
  const { userData, loadingLogin, loginData } = useSelector(
    (state: any) => state.authReducer
  );
  const navigate = useNavigate();

  const processVerifyPin = (data: any) => {
    const header = jsonApiHeader(userData?.sessionId);
    dispatch(
      login(header, {
        number: `${userData?.phoneNumber}`,
        countryCode: '+91',
        password: data?.pin,
        clientId: 'Zebb',
        realm: 'ZebbUser',
      })
    );
  };

  useEffect(() => {
    dispatch(loginSuccess(null));
  }, []);

  useEffect(() => {
    if (loginData) {
      if (!loginData?.verified) {
        alert('Wrong Pin');
        return;
      }
      navigate(`/dashboard`);
      localStorage.setItem('accessData', loginData);
      alert('Sign-Up Successful!', 'success');
    }
  }, [loginData]);

  return (
    <>
      <RegisterLoginWrapper
        ColOne={{ icon: 'lock' }}
        ColTwo={{
          title: 'Enter your 4 digit PIN',
          subtitle: 'Please enter your 4 digit pin',
          inputType: 'pin',
          button: 'Continue',
          buttonLoader: loadingLogin,
          onButtonClick: (val) => processVerifyPin(val),
          infoLabel: (
            <>
              <a
                href='javascript:void(0)'
                style={{ color: 'var(--white)', textDecoration: 'underline' }}
                onClick={() => {
                  dispatch(sendOTPLoginSuccess(null));
                  dispatch(verifyOTPSuccess(null));
                  navigate(`/login?action=forgot_pin`);
                }}
              >
                Forgot PIN?
              </a>
              <p>
                {loginData?.attempts_left
                  ? `Resend Attempts Left - ${loginData.attempts_left} `
                  : null}
              </p>
              <a
                href={`/login?action=${ActionEnum.SEND_OTP}`}
                style={{
                  color: 'var(--white)',
                  textDecoration: 'underline',
                  display: 'block',
                  marginTop: '10px',
                }}
              >
                Back to home
              </a>
            </>
          ),
        }}
      />
    </>
  );
};

export default EnterPin;
