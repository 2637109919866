import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';


const appReducer = combineReducers({
  authReducer: authReducer,
 
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
