import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../pages.scss';
import { VerifyMobile, SignUp, SendEmail, VerifyEmail, VerifyPin, CreatePin } from './Views';
import querystring from 'query-string';
import { ActionEnum } from '../../enums/enums';

const Register = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [action, setAction] = useState<string | string[] | null>('');

  useEffect(() => {
    if (!querystring.parse(location.search)?.action)
      navigate(`/register?action=${ActionEnum.SEND_OTP}`);
    setAction(querystring.parse(location.search)?.action);
  }, [location]);

  return (
    <div className={`zw-container`}>
      {action === ActionEnum.SEND_OTP ? (
        <SignUp />
      ) : action === ActionEnum.VERIFY_OTP ? (
        <VerifyMobile />
      ) : action === ActionEnum.SEND_EMAIL ? (
        <SendEmail />
      ) : action === ActionEnum.VERIFY_EMAIL ? (
        <VerifyEmail />
      ) : action === 'create_pin' ? (
        <CreatePin />
      ) : action === ActionEnum.VERIFY_PIN ? (
        <VerifyPin />
      ) : null}
    </div>
  );
};

export default Register;
