import { ColOne } from '../../RegisterLoginWrapperInterface';
import { Sprite } from '../../..';

const Col1 = ({icon, subtitleOne, subtitleTwo,align}: ColOne) => {
    return <div className={`col-1 align-${align}`}>
        <Sprite id={icon} width={90} height={120} />
        {subtitleOne ? <span>{subtitleOne}</span> : null}
        {subtitleTwo ? <span>{subtitleTwo}</span> : null}
    </div>
};

export default Col1;
