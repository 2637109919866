import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RegisterLoginWrapper } from '../../../../components';
import { storeUserData } from '../../../../redux/auth/authActions';
import { ActionEnum } from '../../../../enums/enums';

const CreatePin = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state: any) => state.authReducer);

  return (
    <>
      <RegisterLoginWrapper
        ColOne={{ icon: 'lock',align:'center' }}
        ColTwo={{
          successMessage: `You have successfully verified your email`,
          title: 'Create 4 digit PIN',
          subtitle:
            'Please create a 4 digit pin and do not share it with anyone',
          inputType: 'pin',
          button: 'Continue',
          onButtonClick: (val) => {
            dispatch(
              dispatch(
                storeUserData({
                  ...userData,
                  pin: val?.pin,
                })
              )
            );
            navigate(
              `/${props.flow ? 'login' : 'register'}?action=${ActionEnum.VERIFY_PIN}${props.flow ? '&flow=forgot_pin' : ''}`
            );
          },
          infoLabel: (
            <span style={{ color: 'var(--grey-one)' }}>
              <a
                href={`/${props.flow ? 'login' : 'register'}?action=${
                  ActionEnum.SEND_OTP
                }`}
                style={{ color: 'var(--white)', textDecoration: 'underline' }}
              >
                Back To Home
              </a>
            </span>
          ),
        }}
      />
    </>
  );
};

export default CreatePin;
