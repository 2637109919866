import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RegisterLoginWrapper } from '../../../../components';
import { ActionEnum } from '../../../../enums/enums';
import {
  sendOTPLogin,
  storeUserData,
  verifyOTP,
  verifyOTPSuccess,
} from '../../../../redux/auth/authActions';
import { alert, jsonApiHeader } from '../../../../utils/Utility';

const VerifyOTP = (props: any) => {
  const navigate = useNavigate();
  const { Title } = Typography;
  const dispatch = useDispatch();

  const [lastAttempt, setLastAttempt] = useState(false);

  const { userData, loadingVerifyOTP, verifyOTPData, loadingSendOTPLogin } = useSelector(
    (state: any) => state.authReducer
  );

  const processVerifyOTP = (data: any) => {
    const header = jsonApiHeader(userData?.sessionId);
    dispatch(
      verifyOTP(header, {
        number: userData?.phoneNumber,
        countryCode: '+91',
        otp: data?.verifyCode,
        realm: 'ZebbUser',
      })
    );
  };

  useEffect(() => {
    dispatch(verifyOTPSuccess(null));
  }, []);

  const processResendOTP = () => {
    const header = jsonApiHeader(userData?.sessionId);
    dispatch(
      sendOTPLogin(header, {
        number: userData?.phoneNumber,
        countryCode: '+91',
        realm: 'ZebbUser',
        journey: 'SIGNUP',
        tncAccepted: true,
      })
    );
  };

  useEffect(() => {
    if (verifyOTPData) {
      if (!verifyOTPData?.verified || !verifyOTPData?.next_action) {
        alert('Wrong OTP!');
        return;
      }
      if (lastAttempt) {
        alert(`Attempts exhausted. Please start again!`);
        window.location.href = '/login';
        return;
      }
      if (verifyOTPData?.attempts_left === 0) {
        setLastAttempt(true);
      }
      if (props.flow) {
        dispatch(
          storeUserData({
            ...userData,
            email: verifyOTPData.email,
          })
        );
        navigate(
          `/login?action=${verifyOTPData.next_action?.toLowerCase()}&flow=forgot_pin`
        );
      } else {
        navigate(`/login?action=${ActionEnum.VERIFY_PIN}`);
      }
    }
  }, [verifyOTPData]);

  return (
    <>
      <RegisterLoginWrapper
        ColOne={{ icon: 'phone-otp' }}
        ColTwo={{
          title: 'Verify your mobile number',
          subtitle: (
            <>
              Please Enter the six-digit code we just sent to your number&nbsp;
              <Title level={4} style={{ color: 'var(--grey)', margin: 0 }}>
                +91 ********{userData?.phoneNumber?.substring(8)}
              </Title>
            </>
          ),
          inputType: 'verifyCode',
          button: 'Verify',
          onButtonClick: (val) => {
            processVerifyOTP(val);
          },
          buttonLoader: loadingVerifyOTP || loadingSendOTPLogin,
          infoLabel: (
            <span style={{ color: 'var(--grey-one)' }}>
              Didn't receive the code?{' '}
              <a
                href='javascript:void(0)'
                onClick={() => processResendOTP()}
                style={{ color: 'var(--white)', textDecoration: 'underline' }}
              >
                Resend Code
              </a>
              <p>
                {verifyOTPData?.attempts_left
                  ? `Resend Attempts Left - ${verifyOTPData.attempts_left} `
                  : null}
              </p>
              <a
                href={`/login?action=${ActionEnum.SEND_OTP}`}
                style={{
                  color: 'var(--white)',
                  textDecoration: 'underline',
                  display: 'block',
                  marginTop: '10px',
                }}
              >
                Back to home
              </a>
            </span>
          ),
        }}
      />
    </>
  );
};

export default VerifyOTP;
