import { notification, message } from "antd";

export const jsonApiHeader = (sessionId, contentType = "application/json") => {
  return {
    "Content-Type": contentType,
    ...sessionId ? { "session-id": sessionId } : null
    // "session-id": sessionId,
  };
};

export const capitalize = (s) => {
  if (s) {
    if (s.length === 0) return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }
};

export const alert = (message, type = "error") => {
  return notification[type]({
    message: capitalize(type),
    description: message,
    duration: 3,
  });
};

export const alert2 = (msg, type) => {
  return message[type](msg);
};
